export function getCargo (stringDate) {
  return `[package]
name = "curl2url_request${stringDate}"
version = "0.1.0"
description = "This project serializes a request"
authors = ["Curl2Url <code@curl2url.com>"]
license = "Apache-2.0"
readme = "README.md"

[dependencies]
reqwest = { version="0.11.24", features=["blocking"]}
`
}

export function getRustReadme (stringDate) {
  return `
Curl2Url: Rust request ${stringDate}
=======================================
This repository is created from Curl2Url, it contains rust code to run the request created.
This project use [Cargo](https://doc.rust-lang.org/cargo/index.html) as dependency management.

# [Install Rust and Cargo](https://doc.rust-lang.org/cargo/getting-started/installation.html)

# Project contents contents:
- main.rs: Contains RUST code to run the request.
- Cargo.toml: File used to create poetry environment.
- Readme.md: This file, used to introduce and explain the project.
`
}
