export function pythonCodePostprocessing (generatedCode, jsonResponseResponse) {
  let imports
  let tabs
  let codeResult
  let serialization = ''
  let isArray
  const isSpaces = true
  if (isSpaces) {
    tabs = '    '
  } else {
    tabs = '\t'
  }
  const isResponseJson = checkIsResponseJson(jsonResponseResponse)
  const splitCode = generatedCode.split(/\n(.*)/s)
  if (isResponseJson) {
    imports = addSerializationBasemodel(splitCode[0])
  } else {
    imports = splitCode[0]
  }
  const tabbedCode = addTab(tabs, splitCode[1])
  const codeRequest = 'def make_request() -> requests.Response:' + tabbedCode
  let validSerialization = false
  try {
    const serializationResponse = responseSerialization(tabs, jsonResponseResponse)
    serialization = serializationResponse[0]
    isArray = serializationResponse[1]
    validSerialization = true
  } catch (error) {
    // Invalid serialization
  }
  if (isResponseJson && validSerialization) {
    if (isArray) {
      codeResult = '\n\nresponse = make_request() \nprint(Response(root=response.json()))\n'
    } else {
      codeResult = '\n\nresponse = make_request() \nprint(Response(**response.json()))\n'
    }
  } else {
    codeResult = '\n\nresponse = make_request() \nprint(response.content)\n'
  }
  return imports + '\n\n\n' + codeRequest + '\n' + serialization + codeResult
}

function checkIsResponseJson (jsonResponseResponse) {
  if ('headers' in jsonResponseResponse) {
    if ('content-type' in jsonResponseResponse.headers) {
      return jsonResponseResponse.headers['content-type'].includes('json')
    }
  }
  return false
}

function addSerializationBasemodel (imports) {
  return imports + '\nfrom pydantic import BaseModel, RootModel, Field\nfrom typing import Dict, List, Optional, Any, Tuple\n'
}

function responseSerialization (tabs, jsonResponseResponse) {
  const parsedJson = JSON.parse(jsonResponseResponse.body)
  return serialize(tabs, parsedJson, 'Response')
}

function serialize (tabs, parsedJson, elementKey) {
  const dictionaryClasses = {}
  const level = 0
  let isArray = false
  if (Array.isArray(parsedJson) && parsedJson.length !== 0) {
    for (let i = 0; i < parsedJson.length; i++) {
      convertObjectToPythonSerializer(dictionaryClasses, parsedJson[i], 'ResponseElement', level, '')
    }
    isArray = true
  } else if (parsedJson.constructor === Object) {
    convertObjectToPythonSerializer(dictionaryClasses, parsedJson, 'Response', level, '')
  }
  let serialization = convertDictionaryClassToCode(tabs, dictionaryClasses)
  if (isArray) {
    serialization += '\n\nclass Response(RootModel):\n' + tabs + 'root: List[ResponseElement]\n'
  }
  return [serialization, isArray]
}

function isInvalidCharacter (variable) {
  const re = /^[A-Za-z_]\w*$/
  if (re.test(variable)) {
    return false
  }
  return true
}

function replaceAllSpecialCharacters (string) {
  let resultString = string.replace(/[^a-zA-Z0-9]/g, 'z')
  if (string.length >= 1 && !isNaN(parseInt(string.charAt(0)))) {
    resultString = 'a' + resultString
  }
  return resultString
}

function getKeyFieldName (variable) {
  if (isInvalidCharacter(variable)) {
    const newVariable = replaceAllSpecialCharacters(variable)
    return [newVariable, variable]
  }
  return [variable, variable]
}

function matchClassesWithVariables (dictionaryClasses) {
  const hashDict = {}
  const elementKeyToKey = {}
  for (const [key, value] of Object.entries(dictionaryClasses)) {
    const variables = Object.entries(value.variables)
    variables.sort()
    let variablesHash = ''
    for (const [varKey, varValue] of variables) {
      const valuesArray = Array.from(varValue)
      valuesArray.sort()
      const hashKey = varKey + '-' + Array.from(valuesArray).join(',') + '_'
      variablesHash += hashKey
    }
    if (variablesHash in hashDict) {
      const commonClassName = replaceAllSpecialCharacters(hashDict[variablesHash])
      value.className = commonClassName
      dictionaryClasses[elementKeyToKey[commonClassName]].className = commonClassName
    } else {
      hashDict[variablesHash] = replaceAllSpecialCharacters(value.elementKey)
      elementKeyToKey[replaceAllSpecialCharacters(value.elementKey)] = key
    }
  }
}

function capitaliseFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function convertDictionaryClassToCode (tabs, dictionaryClasses) {
  matchClassesWithVariables(dictionaryClasses)
  let serialization = ''
  const keysArray = Object.keys(dictionaryClasses)
  keysArray.sort()
  keysArray.reverse()
  const classesNamesDict = {}
  for (const key of keysArray) {
    const valueObject = dictionaryClasses[key]
    const elementKey = valueObject.elementKey
    let cleanClassName = replaceAllSpecialCharacters(elementKey)
    cleanClassName = capitaliseFirstLetter(cleanClassName)
    if (valueObject.className !== undefined) {
      classesNamesDict[cleanClassName] = capitaliseFirstLetter(valueObject.className)
    }
    if ('className' in valueObject && capitaliseFirstLetter(valueObject.className) !== cleanClassName) {
      continue
    }
    serialization += `\n\nclass ${cleanClassName}(BaseModel):\n`
    for (const [key, value] of Object.entries(valueObject.variables)) {
      const fieldsArray = getKeyFieldName(key)
      const keyName = fieldsArray[0]
      const keyNameUpperCase = capitaliseFirstLetter(keyName)
      const fieldName = fieldsArray[1]
      if (value.size === 1) {
        const valueToWrite = value.values().next().value
        let cleanValueToWrite
        if (valueToWrite.includes('[')) {
          cleanValueToWrite = valueToWrite
        } else {
          cleanValueToWrite = replaceAllSpecialCharacters(valueToWrite)
        }
        if (valueToWrite === 'null') {
          serialization += tabs + keyName + `: Optional[str] = Field(alias="${fieldName}", default=None)\n`
        } else {
          if (keyNameUpperCase in classesNamesDict) {
            cleanValueToWrite = replaceAllSpecialCharacters(classesNamesDict[keyNameUpperCase])
            // cleanValueToWrite = capitaliseFirstLetter(valueObject.className)
          }
          serialization += tabs + keyName + ': ' + cleanValueToWrite + ` = Field(alias="${fieldName}")\n`
        }
      } else {
        if (value.has('null')) {
          value.delete('null')
          let cleanValueToWrite = replaceAllSpecialCharacters(value.values().next().value)
          if (keyNameUpperCase in classesNamesDict) {
            cleanValueToWrite = replaceAllSpecialCharacters(classesNamesDict[keyNameUpperCase])
            // cleanValueToWrite = capitaliseFirstLetter(valueObject.className)
          }
          serialization += tabs + keyName + ': Optional[' + cleanValueToWrite + `] = Field(alias="${fieldName}", default=None)\n`
        } else {
          console.log(value)
        }
      }
    }
    if (Object.keys(valueObject.variables).length === 0) {
      serialization += `${tabs}class Config:\n${tabs}${tabs}extra = "forbid"\n`
    }
  }
  return serialization
}

const zeroPad = (num, places) => String(num).padStart(places, '0')

function addKeysToDict (objectDict, keys) {
  for (const key of keys) {
    const type = 'null'
    key in objectDict.variables ? objectDict.variables[key].add(type) : objectDict.variables[key] = new Set([type])
  }
}

function convertObjectToPythonSerializer (objectsDict, parsedJson, elementKey, level, parent) {
  const dictKey = zeroPad(level, 4) + '_' + elementKey
  const objectDict = dictKey in objectsDict ? objectsDict[dictKey] : {}
  if (Object.keys(objectDict).length === 0) {
    objectDict.elementKey = elementKey
    objectDict.variables = {}
    objectDict.level = level
  } else {
    const storedVariables = new Set(Object.keys(objectDict.variables))
    const actualVariables = new Set(Object.keys(parsedJson))
    const aMinusB = new Set([...storedVariables].filter(x => !actualVariables.has(x)))
    const bMinusA = new Set([...actualVariables].filter(x => !storedVariables.has(x)))
    addKeysToDict(objectDict, bMinusA)
    addKeysToDict(objectDict, aMinusB)
  }
  for (const [key, value] of Object.entries(parsedJson)) {
    let type
    if (value === null) {
      type = 'null'
    } else if (typeof value === 'string') {
      type = 'str'
    } else if (typeof value === 'boolean') {
      type = 'bool'
    } else if (typeof value === 'number') {
      if (value.toString().includes('.')) {
        type = 'float'
      } else {
        type = 'int'
      }
    } else if (Array.isArray(value) && value.length !== 0) {
      const className = key.charAt(0).toUpperCase() + key.slice(1)
      type = `List[${className}]`
      if (isHomogenousArray(value)) {
        type = getValueType(value[0])
      } else {
        type = 'List[Any]'
      }
      // convertObjectToPythonSerializer(objectsDict, value[0], className, level + 1, elementKey)
    } else if (Array.isArray(value)) {
      const className = key.charAt(0).toUpperCase() + key.slice(1)
      type = `List[${className}]`
      convertObjectToPythonSerializer(objectsDict, value, className, level + 1, elementKey)
    } else if (typeof value === 'object') {
      type = key.charAt(0).toUpperCase() + key.slice(1)
      convertObjectToPythonSerializer(objectsDict, value, type, level + 1, elementKey)
    } else if (typeof value === 'undefined') {
      type = 'undefined'
    }
    key in objectDict.variables ? objectDict.variables[key].add(type) : objectDict.variables[key] = new Set([type])
  }
  objectsDict[dictKey] = objectDict
}

function isHomogenousArray (array) {
  const typeElements = new Set()
  for (const arrayElement of array) {
    const elementType = typeof arrayElement
    typeElements.add(elementType)
  }
  return typeElements.size === 1
}

function getRandomName () {
  return Math.random().toString(36).slice(2, 7)
}

function getValueType (value) {
  let type
  if (value === null) {
    type = 'null'
  } else if (typeof value === 'string') {
    type = 'str'
  } else if (typeof value === 'boolean') {
    type = 'bool'
  } else if (typeof value === 'number') {
    if (value.toString().includes('.')) {
      type = 'float'
    } else {
      type = 'int'
    }
  } else if (Array.isArray(value) && value.length !== 0) {
    if (isHomogenousArray(value)) {
      type = getValueType(value[0])
    } else {
      type = 'List[Any]'
    }
  } else if (Array.isArray(value)) {
    type = 'List[Any]'
  } else if (typeof value === 'object') {
    type = 'Any'
  } else if (typeof value === 'undefined') {
    type = 'undefined'
  }
  return type
}

function addTab (tabs, code) {
  code += '\nreturn response'
  return code.replaceAll('\n', '\n' + tabs)
}

export function getPoetry (stringDate) {
  return `
[tool.poetry]
name = "curl2url_request${stringDate}"
version = "0.1.0"
description = "This project serializes a request"
authors = ["Curl2Url <contact@curl2url.com>"]
license = "Apache-2.0"
readme = "README.md"

[tool.poetry.dependencies]
python = "^3.10"
requests = "^2.31.0"
pydantic = "^2.5.3"


[build-system]
requires = ["poetry-core"]
build-backend = "poetry.core.masonry.api"
`
}

export function getPythonReadme (stringDate) {
  return `
Curl2Url: Python request ${stringDate}
=======================================
This repository is created from Curl2Url, it contains python code to run the request created.
This project use [Poetry](https://python-poetry.org/docs/) as dependency management.

# Project contents contents:
- main.py: Contains python code to run the requests and response serialization if available.
- pyproject.toml: File used to create poetry environment.
- Readme.md: This file, used to introduce and explain the project.
`
}
