import '../main.scss'
import { backendDomain, protocol } from '../constants.js'
import {
  getCookie,
  getCurl2Url,
  getRequestData,
  requestToCurl,
  setCommonHtml,
  transformDropdownContent,
  downloadRequestSerialization
} from '../common_js.js'
import hljs from 'highlight.js/lib/core'
import plaintext from 'highlight.js/lib/languages/plaintext'
import bash from 'highlight.js/lib/languages/bash'
import clojure from 'highlight.js/lib/languages/clojure'
import csharp from 'highlight.js/lib/languages/csharp'
import dart from 'highlight.js/lib/languages/dart'
import elixir from 'highlight.js/lib/languages/elixir'
import go from 'highlight.js/lib/languages/go'
import http from 'highlight.js/lib/languages/http'
import java from 'highlight.js/lib/languages/java'
import javascript from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import kotlin from 'highlight.js/lib/languages/kotlin'
import matlab from 'highlight.js/lib/languages/matlab'
import objectivec from 'highlight.js/lib/languages/objectivec'
import ocaml from 'highlight.js/lib/languages/ocaml'
import php from 'highlight.js/lib/languages/php'
import powershell from 'highlight.js/lib/languages/powershell'
import python from 'highlight.js/lib/languages/python'
import r from 'highlight.js/lib/languages/r'
import ruby from 'highlight.js/lib/languages/ruby'
import rust from 'highlight.js/lib/languages/rust'
import swift from 'highlight.js/lib/languages/swift'
import yaml from 'highlight.js/lib/languages/yaml'

hljs.registerLanguage('plaintext', plaintext)

hljs.registerLanguage('bash', bash)
hljs.registerLanguage('clojure', clojure)
hljs.registerLanguage('csharp', csharp)
hljs.registerLanguage('dart', dart)
hljs.registerLanguage('elixir', elixir)
hljs.registerLanguage('go', go)
hljs.registerLanguage('http', http)
hljs.registerLanguage('java', java)
hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('json', json)
hljs.registerLanguage('kotlin', kotlin)
hljs.registerLanguage('matlab', matlab)
hljs.registerLanguage('objectivec', objectivec)
hljs.registerLanguage('ocaml', ocaml)
hljs.registerLanguage('php', php)
hljs.registerLanguage('powershell', powershell)
hljs.registerLanguage('python', python)
hljs.registerLanguage('r', r)
hljs.registerLanguage('ruby', ruby)
hljs.registerLanguage('rust', rust)
hljs.registerLanguage('swift', swift)
hljs.registerLanguage('yaml', yaml)

async function runCurl2Url (curl2UrlId) {
  const token = getCookie('token')
  const bearerToken = `Bearer ${token}`
  const options = {
    method: 'GET',
    headers: new Headers({
      Origin: 'http://localhost:8280/',
      'Accept-Encoding': 'gzip, deflate',
      'Accept-Language': 'en-US,en;q=0.8',
      'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Referer: 'http://localhost:8280/',
      'X-Requested-With': 'XMLHttpRequest',
      Connection: 'keep-alive',
      authorization: bearerToken
    }),
    mode: 'cors'
  }
  const url = `${protocol}://${backendDomain}/curl2url/run/${curl2UrlId}`
  return await fetch(url, options)
}

function displayRequest (jsonResponse) {
  const request = document.getElementById('request-method')
  const requestUrl = document.getElementById('request-url')
  const requestSpinner = document.getElementById('request-spinner')
  const requestLink = document.createElement('a')
  switch (jsonResponse.method) {
    case 'GET':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET'
      requestLink.className = 'btn btn-primary'
      break
    case 'POST':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST'
      requestLink.className = 'btn btn-success'
      break
    case 'DELETE':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE'
      requestLink.className = 'btn btn-danger'
      break
    case 'PUT':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT'
      requestLink.className = 'btn btn-warning'
      break
    case 'PATCH':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH'
      requestLink.className = 'btn btn-warning'
      break
    case 'OPTIONS':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/OPTIONS'
      requestLink.className = 'btn btn-secondary'
      break
    case 'HEAD':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD'
      requestLink.className = 'btn btn-info'
      break
    case 'CONNECT':
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/CONNECT'
      requestLink.className = 'btn btn-dark'
      break
    default:
      requestLink.href = 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET'
      requestLink.className = 'btn btn-primary'
  }
  appendFullUrl('request-full-url-body', jsonResponse)
  appendDivHeaders('request-headers-body', jsonResponse)
  appendDivBody('request-body-body', jsonResponse)
  requestLink.textContent = jsonResponse.method.toUpperCase()
  const spinner = document.createElement('div')
  spinner.className = 'spinner-border text-primary'
  requestSpinner.appendChild(spinner)
  requestUrl.textContent = jsonResponse.url
  request.appendChild(requestLink)
  const serverLocationHtml = document.getElementById('server-location')
  const location = document.getElementById('location')
  const proxyLocationHtml = document.getElementById('proxy-location')
  const proxy = document.getElementById('proxy')
  const proxyLocation = jsonResponse.proxyLocation
  if (proxyLocation === null) {
    location.textContent = jsonResponse.serverLocation
    serverLocationHtml.style.display = 'flex'
  } else {
    proxy.textContent = proxyLocation
    proxyLocationHtml.style.display = 'flex'
  }
}

const showRequest = () => {
  document.getElementById('request').style.display = 'block'
}

const showResponse = () => {
  document.getElementById('response').style.display = 'block'
}

const showNotFound = () => {
  document.getElementById('not-found').style.display = 'block'
}

const showExecutingRequest = () => {
  const requestTitle = document.getElementById('request-title')
  const requestTitleContainer = document.getElementById('request-title-container')
  requestTitleContainer.className = requestTitleContainer.className.replace('col-sm-2', 'col-sm-4')
  requestTitle.innerText = 'Executing Request'
}

const showRequestTitle = () => {
  const requestTitle = document.getElementById('request-title')
  const requestTitleContainer = document.getElementById('request-title-container')
  requestTitleContainer.className = requestTitleContainer.className.replace('col-sm-4', 'col-sm-2')
  requestTitle.innerText = 'Request'
}

const showError = () => {
  document.getElementById('error').style.display = 'block'
}

const setErrorTitle = (title) => {
  document.getElementById('error-title').innerText = title
}

const setErrorText = (text) => {
  document.getElementById('error-text').innerText = text
}

function endDisplayResponse () {
  const requestSpinner = document.getElementById('request-spinner')
  requestSpinner.removeChild(requestSpinner.lastChild)
}

function appendDivHeaders (headersId, jsonResponse) {
  const responseHeadersDiv = document.getElementById(headersId)
  const list = document.createElement('ul')
  for (const [key, value] of Object.entries(jsonResponse.headers)) {
    const listElement = document.createElement('li')
    listElement.textContent = `${key}: ${value}`
    list.appendChild(listElement)
  }
  responseHeadersDiv.appendChild(list)
}

function appendFullUrl (fullUrlId, jsonResponse) {
  const responseFullUrlDiv = document.getElementById(fullUrlId)
  const htmlText = document.createElement('p')
  htmlText.textContent = jsonResponse.rawUrl
  responseFullUrlDiv.append(htmlText)
}

function formUrlEncoded (responseBody) {
  if (responseBody === '') {
    return ''
  }
  const responseBodyJson = JSON.parse(responseBody)
  const formBody = []
  for (const property in responseBodyJson) {
    // const encodedKey = encodeURIComponent(property)
    // const encodedValue = encodeURIComponent(responseBodyJson[property])
    const isArray = Array.isArray(responseBodyJson[property])
    if (isArray) {
      formBody.push(property + '=[' + responseBodyJson[property] + ']')
    } else {
      formBody.push(property + '=' + responseBodyJson[property])
    }
  }
  return formBody.join('&')
}

function appendDivBody (bodyId, jsonResponse) {
  const responseHeadersDiv = document.getElementById(bodyId)
  const htmlText = document.createElement('p')
  const requestArray = getRequestData(jsonResponse)
  const requestData = requestArray[0]
  if (requestData !== null) {
    htmlText.textContent = requestData
  }
  responseHeadersDiv.appendChild(htmlText)
}

function displayResponseResponse (jsonResponse, jsonRequest) {
  endDisplayResponse()
  const status = document.getElementById('status')
  status.textContent = jsonResponse.statusCode
  const responseTime = document.getElementById('response-time')
  if (jsonRequest.proxyLocation === null) {
    responseTime.textContent = (jsonResponse.responseTime * 1000).toFixed(3) + ' ms'
  } else if (jsonRequest.proxyLocation === 'RANDOM') {
    responseTime.textContent = 'Time using random proxy is not computed'
  } else {
    responseTime.textContent = (jsonResponse.responseTime * 1000).toFixed(3) + ' ms (time using proxies is approximated)'
  }
  appendDivHeaders('response-headers-body', jsonResponse)
  if (jsonResponse.body !== '') {
    // appendDivBody('response-body-body', jsonResponse)
    const responseBody = document.getElementById('response-body-body')
    responseBody.textContent = jsonResponse.body
  }
}

function setCollapseExpandElements (elementName) {
  const requestDiv = document.getElementById(`${elementName}-content`)
  requestDiv.addEventListener('show.bs.collapse', event => {
    const expandRequestHeaders = document.getElementById(`expand-${elementName}`)
    expandRequestHeaders.style.display = 'none'
    const collapseRequestHeaders = document.getElementById(`collapse-${elementName}`)
    collapseRequestHeaders.style.display = 'flex'
  })

  requestDiv.addEventListener('hide.bs.collapse', event => {
    const expandRequestHeaders = document.getElementById(`collapse-${elementName}`)
    expandRequestHeaders.style.display = 'none'
    const collapseRequestHeaders = document.getElementById(`expand-${elementName}`)
    collapseRequestHeaders.style.display = 'flex'
  })
}

setCollapseExpandElements('request-full-url')
setCollapseExpandElements('request-headers')
setCollapseExpandElements('request-body')
setCollapseExpandElements('response-headers')
setCollapseExpandElements('response-body')

function requestsThrottled (response) {
  endDisplayResponse()
  showError()
  setErrorTitle('Requests throttled')
  setErrorText(response.message)
}

async function displayResponsesResponse (responseResponse, jsonRequest) {
  const jsonResponseResponse = await responseResponse.json()
  setDownloadRequest(jsonRequest, jsonResponseResponse)
  if (responseResponse.status === 200) {
    showRequestTitle()
    displayResponseResponse(jsonResponseResponse, jsonRequest)
    showResponse()
  } else if (responseResponse.status === 402) {
    endDisplayResponse()
    showError()
    setErrorTitle('Payment required')
    setErrorText('Your account need more balance to run the request')
  } else if (responseResponse.status === 408) {
    endDisplayResponse()
    showError()
    setErrorTitle('Request timeout')
    setErrorText('Request took too long to complete')
  } else if (responseResponse.status === 409) {
    endDisplayResponse()
    showError()
    setErrorTitle('Invalid request or response')
    setErrorText('The request has an invalid request or response')
  } else if (responseResponse.status === 426) {
    const token = getCookie('token')
    if (token === '') {
      endDisplayResponse()
      showError()
      setErrorTitle('Response max size exceeded')
      setErrorText('You exceeded max response size. Please upgrade your account to be able to handle this request')
    } else {
      endDisplayResponse()
      showError()
      setErrorTitle('Response max size exceeded')
      setErrorText('This request exceeded max response size')
    }
  } else if (responseResponse.status === 429) {
    requestsThrottled(jsonResponseResponse)
  } else {
    endDisplayResponse()
    showError()
    setErrorTitle('Unexpected Error')
    setErrorText('Server throw unexpected error, we are working to solve it.')
  }
}

async function responseFlow (path, jsonRequest) {
  try {
    const responseResponse = await runCurl2Url(path)
    await displayResponsesResponse(responseResponse, jsonRequest)
  } catch (error) {
    displayNoResponse(error)
  }
}

function copyCurlToClipboard (jsonResponse) {
  const curl = requestToCurl(jsonResponse)
  navigator.clipboard.writeText(curl)
}

function setCopyCurlListener (jsonResponse) {
  const copyCurlButton = document.getElementById('copy-curl-button')
  copyCurlButton.addEventListener('click', (e) => {
    e.preventDefault()
    copyCurlToClipboard(jsonResponse)
  })
}

function setEditButtonListener (path) {
  const editCurlButton = document.getElementById('edit-curl-button')
  editCurlButton.addEventListener('click', (e) => {
    e.preventDefault()
    window.location.href = '/?request-id=' + path
  })
}

async function displayCurl2UrlRequestResponse (responseRequest, path) {
  const jsonRequest = await responseRequest.json()
  if (responseRequest.status === 200) {
    setCopyCurlListener(jsonRequest)
    setEditButtonListener(path)
    displayRequest(jsonRequest)
    showRequest()
    const requestSpinner = document.getElementById('request-spinner')
    if (jsonRequest.execution === 'ON_ACCESS') {
      requestSpinner.style.display = 'block'
      showExecutingRequest()
      await responseFlow(path, jsonRequest)
    } else {
      const runButton = document.getElementById('run-button')
      runButton.style.display = 'block'
      runButton.addEventListener('click', async (e) => {
        runButton.setAttribute('disabled', true)
        requestSpinner.style.display = 'block'
        showExecutingRequest()
        await responseFlow(path, jsonRequest)
      })
    }
  } else if (responseRequest.status === 402) {
    endDisplayResponse()
    showError()
    setErrorTitle('Payment required')
    setErrorText('Account need more balance to run the request')
  } else if (responseRequest.status === 407) {
    endDisplayResponse()
    showError()
    setErrorTitle('Payment required')
    setErrorText(responseRequest.message)
  } else if (responseRequest.status === 429) {
    requestsThrottled(jsonRequest)
  } else {
    showNotFound()
  }
}

function displayNoResponse (error) {
  console.error(error)
  endDisplayResponse()
  showError()
  setErrorTitle('Service not available')
  setErrorText('At the moment, there is not connection with the server')
}

async function curl2urlFlow () {
  const path = window.location.pathname.replace(/^\/+/, '').replace(/\/+$/, '').split('/')[1]
  try {
    const responseRequest = await getCurl2Url(path)
    await displayCurl2UrlRequestResponse(responseRequest, path)
  } catch (error) {
    displayNoResponse(error)
  }
}

const dropDownMenus = document.querySelectorAll('.dropdown-menu li a')

Array.from(dropDownMenus).map((element) => {
  element.addEventListener('click', function () {
    this.closest('.dropdown').querySelector('.dropdown-toggle').textContent = element.textContent
    const modalLabel = document.getElementById('myLargeModalLabel')
    if (element.textContent === 'Python') {
      modalLabel.textContent = 'Request and serialization code'
    } else {
      modalLabel.textContent = 'Request code'
    }
  })
  return element
})

function setDownloadRequest (jsonRequest, jsonResponseResponse) {
  const curl = requestToCurl(jsonRequest)
  const downloadButton = document.getElementById('download-button')
  downloadButton.addEventListener('click', (e) => {
    const downloadLanguage = document.getElementById('dropdown-language')
    const cleanLanguage = transformDropdownContent(downloadLanguage.textContent)
    console.log(cleanLanguage)
    e.preventDefault()
    downloadRequestSerialization(curl, jsonResponseResponse, cleanLanguage)
  })
}

// addCollapse()
setCommonHtml()
await curl2urlFlow()
